@layer base {
  .loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.wrapper {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding: var(--sk-space-24);
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.block {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.link {
  font-size: var(--sk-font-size-1);
}

.checkbox {
  margin-bottom: var(--sk-space-64);
}

.ctaBlock {
  position: absolute;
  right: var(--sk-space-24);
  bottom: var(--sk-space-24);
  left: var(--sk-space-24);
  text-align: right;
}

.button {
  width: 100%;
}

@media (--medium-viewport) {
  .form {
    align-items: center;
    justify-content: center;
    width: 556px;
    margin: 0 auto;
    gap: var(--sk-space-64);
  }

  .block {
    padding: var(--sk-space-32);
    border: 2px solid var(--sk-color-grey-100);
    border-radius: var(--sk-radius-16);
  }

  .checkbox {
    margin-bottom: 0;
  }

  .ctaBlock {
    position: static;
  }

  .button {
    width: auto;
  }
}
}