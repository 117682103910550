@layer base {
  .main {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  padding: 0 var(--sk-space-8);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-100);
  color: var(--sk-color-purple-700);
}

.label {
  overflow: hidden;
}

}