@layer base {
  .hdr-Icon {
  --iconSize: 20px;
  width: var(--iconSize);
  min-width: var(--iconSize);
  height: var(--iconSize);
  fill: currentcolor;
  vertical-align: middle;
}

.hdr-Icon--isBig {
  --iconSize: 24px;
}

}