@layer base {
  .main {
  --mj-breakpoint: 80rem;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;
  --typo-body-2-font-size: 16px;
  --typo-body-2-line-height: 24px;
  --typo-body-3-font-size: 14px;
  --typo-body-3-line-height: 24px;
  --color-primary: #4dc942; /* Legacy Green */
  --shadow-default: 0 4px 8px rgb(118 118 120 / 20%);
  position: relative;
  z-index: var(--z-index-website-menu);
  background-color: var(--themeMainColor, var(--sk-color-white));
  box-shadow: var(--shadow-default);
}

.nav {
  --layout-size: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr auto;
  max-width: var(--layout-size);
}

.link {
  color: var(--color-grey--900);
  text-decoration: none;
}

.logo {
  display: flex;
  grid-column-start: 1;
  align-items: center;
  height: var(--sk-space-64);
}

.dropdown {
  display: flex;
  grid-column-start: 2;
}

.topNav {
  grid-column: 1 / span 2;
  grid-row-start: 2;
}

.primaryNav {
  grid-column: 1 / span 2;
  grid-row-start: 3;
}

.primaryNav__hidden {
  visibility: hidden;
}

.language {
  grid-column: 1 / span 2;
  grid-row-start: 4;
}

@media (width < 64rem) {
  /* --medium-and-less-only */

  .main .nav:global(.nav--open) {
    position: fixed;
    z-index: inherit;
    top: 0;
    left: 0;
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    overflow-y: auto;
    background-color: inherit;
  }

  .main .nav:not(:global(.nav--open)) .topNav,
  .main .nav:not(:global(.nav--open)) .primaryNav,
  .main .nav:not(:global(.nav--open)) .language,
  .main .nav:global(.nav--open) .account {
    display: none;
  }

  .dropdown {
    flex-direction: row-reverse;
  }

  .primaryNav {
    padding-top: var(--sk-space-16);
    padding-bottom: var(--sk-space-40);
  }

  .topNav + .primaryNav {
    display: flex;
    align-items: flex-end;
    padding-top: var(--sk-space-48);
  }

  .dropdownButton {
    display: flex;
    margin-right: var(--sk-space-12);
  }

  .app-Header__dropdownMenu {
    visibility: hidden;
    position: absolute;
    width: 100%;
  }

  .language {
    padding: var(--sk-space-32) var(--sk-space-24);
  }
}

/* Only for devices with small screen height */

@media (height < 700px) and (width < 1024px) {
  .language {
    padding-top: var(--sk-space-48);
  }
}

@media (width >= 64rem) {
  /* --large-viewport */
  .main__fixed {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .header--anonymousUser {
    background: linear-gradient(0deg, var(--sk-color-white) 61.54%, var(--sk-color-grey-700) 61.54%);
  }

  .nav {
    --layout-size: var(--mj-breakpoint);
    box-sizing: border-box;
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: auto auto;
    margin: auto;
    padding: 0 var(--sk-space-24);
  }

  .logo {
    grid-column: 1;
    grid-row-start: 2;
  }

  .app-Header__logo {
    height: var(--sk-space-32);
  }

  .dropdown {
    grid-column: 3;
    grid-row-start: 2;
  }

  .topNav {
    grid-column: 1 / span 4;
    grid-row-start: 1;
  }

  .primaryNav {
    grid-column: 2;
    grid-row-start: 2;
    text-align: right;
  }

  .language {
    display: flex;
    grid-column: 4;
    grid-row-start: 2;
  }

  .dropdownButton {
    display: none;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .main {
  --color-primary: var(--sk-color-green-500);
  background-color: var(--sk-color-white);
}

[data-theme="jobteaser-uishift"] .logo path {
  fill: var(--sk-color-black);
}

@media (width >= 64rem) {
  /* --large-viewport */
  [data-theme="jobteaser-uishift"] .header--anonymousUser {
    background: linear-gradient(0deg, var(--sk-color-white) 61.54%, var(--sk-color-grey-700) 61.54%);
  }
}

/* we just display the logo on the printed version of the page */

@media print {
  .nav > :not(.logo) {
    display: none;
  }

  .logo {
    height: var(--sk-space-48);
    margin: auto;
  }
}

}