@layer base {
  .app-Nav {
  width: 100%;
  margin: 0;
  padding: 0;
}

.app-Nav__item {
  display: inherit;
  align-items: center;
}

.app-Nav__link {
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: var(--sk-space-16) 0;
  color: var(--themeButtonTextColor, var(--sk-color-black));
  font-size: var(--typo-body-2-font-size);
  font-weight: var(--black);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
  white-space: nowrap;
}

.app-Nav__link:hover {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.app-Nav__link--active {
  color: var(--themeButtonTextColor, var(--color-primary));
}

.app-Nav__newTag {
  margin-left: var(--sk-space-8);
}

@media (width < 64rem) { /* --medium-and-less-only */
  .app-Nav__link {
    margin: 0 var(--sk-space-24);
    border-bottom: thin solid var(--themeButtonTextColor, var(--sk-color-grey-100));
  }

  .app-Nav__personasList {
    margin-top: var(--sk-space-16);
    padding: 0;
    list-style: none;
  }

  .app-Nav__personasItem--active {
    display: none;
  }

  .app-Nav__personasLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 var(--sk-space-24);
    padding: var(--sk-space-16) 0;
    border-bottom: thin solid var(--sk-color-grey-100);
    color: var(--sk-color-black);
    font-size: var(--typo-body-2-font-size);
    line-height: var(--typo-body-2-line-height);
    text-decoration: none;
  }
}

@media (width >= 64rem) { /* --large-viewport */
  .app-Nav__personas {
    --layout-size: var(--mj-breakpoint);
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    max-width: var(--layout-size);
    min-height: 40px;
    margin: auto;
  }

  .app-Nav__personasList {
    display: flex;
    flex-grow: 1;
    gap: 0 var(--sk-space-24);
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .app-Nav__personasItem {
    display: flex;
  }

  .app-Nav__personasLink {
    position: relative;
    padding: var(--sk-space-8) 0;
    color: var(--sk-color-black);
    font-size: var(--typo-body-3-font-size);
    line-height: var(--typo-body-3-line-height);
    text-decoration: none;
    text-transform: capitalize;
  }

  .app-Nav__personasLink--active {
    font-weight: var(--font-weight-bold);
  }

  .app-Nav__personasLink:not(.app-Nav__personasLink--active) {
    color: var(--sk-color-white);
  }

  .app-Nav__personasLink:hover,
  .app-Nav__personasLink--active {
    color: var(--color-primary);
  }

  .app-Nav__personasLink:hover::after,
  .app-Nav__personasLink--active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid var(--color-primary);
  }

  .app-Nav__personasLinkIcon {
    display: none;
  }

  .app-Nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
  }

  .app-Nav__link {
    display: flex;
    align-items: center;
    height: var(--sk-space-64);
    padding: 0 0.75rem;
    font-size: 0.875rem;
    font-weight: var(--font-weight-regular);
    text-align: center;
  }

  .app-Nav__link--active {
    font-weight: var(--font-weight-bold);
  }

  .app-Nav__link:hover::after,
  .app-Nav__link--active::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: var(--sk-space-12);
    width: calc(100% - var(--sk-space-24));
    height: 0.125rem;
    background-color: var(--themeButtonTextColor, var(--color-primary));
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */
[data-theme="jobteaser-uishift"] .app-Nav__item--offers { order: 1; }
[data-theme="jobteaser-uishift"] .app-Nav__item--companies { order: 2; }
[data-theme="jobteaser-uishift"] .app-Nav__item--events { order: 3; }
[data-theme="jobteaser-uishift"] .app-Nav__item--community { order: 4; }
[data-theme="jobteaser-uishift"] .app-Nav__item--jobs { order: 5; }
[data-theme="jobteaser-uishift"] .app-Nav__item--jobRoles { order: 5; }
[data-theme="jobteaser-uishift"] .app-Nav__item--advices { order: 6; }
[data-theme="jobteaser-uishift"] .app-Nav__item--handbook { order: 7; }
[data-theme="jobteaser-uishift"] .app-Nav__item--ressources { order: 8; }
[data-theme="jobteaser-uishift"] .app-Nav__item--appointment { order: 9; }
[data-theme="jobteaser-uishift"] .app-Nav__item--careerExplorer { order: 10; }
[data-theme="jobteaser-uishift"] .app-Nav__item--login { order: 11; }

[data-theme="jobteaser-uishift"] .app-Nav__link {
  color: var(--sk-color-black);
  font-weight: var(--font-weight-regular);
}

[data-theme="jobteaser-uishift"] .app-Nav__link--active {
  font-weight: var(--font-weight-semibold);
}

[data-theme="jobteaser-uishift"] .app-Nav__linkLang {
  color: var(--sk-color-white);
}

@media (width < 64rem) { /* --medium-and-less-only */
  [data-theme="jobteaser-uishift"] .app-Nav {
    display: flex;
    flex-direction: column;
  }

  [data-theme="jobteaser-uishift"] .app-Nav__link {
    width: 100%;
    border-bottom-color: var(--sk-color-grey-100);
  }
}

@media (width >= 64rem) { /* --large-viewport */
  [data-theme="jobteaser-uishift"] .app-Nav__item--dashboard {
    display: none;
  }

  [data-theme="jobteaser-uishift"] .app-Nav__link:hover::after,
  [data-theme="jobteaser-uishift"] .app-Nav__link--active::after {
    background-color: var(--sk-color-black);
  }
}

}