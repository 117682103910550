@layer base {
  .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: calc(var(--sk-space-32)/2);
  font-size: 0.8125rem;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  display: inline-block;
  padding: var(--sk-space-16);
  color: var(--themeHeaderColor, var(--sk-color-white));
  font-size: var(--typo-body-3-font-size);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
}

.link__small {
  font-size: 13px;
  line-height: var(--typo-body-3-line-height);
}

.copyright {
  margin-top: calc(var(--sk-space-32)/2);
  color: var(--themeHeaderColor, var(--sk-color-white));
  line-height: 1.125rem;
  text-align: center;
}

@media (width >= 48rem) { /* --medium-viewport */
  .main {
    padding: calc(var(--sk-space-32)/2) 0;
  }

  .links {
    flex-direction: row;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    flex-wrap: wrap;
  }

  .item {
    line-height: inherit;
  }

  .copyright {
    text-align: right;
  }
}

@media (width >= 64rem) { /* --large-viewport */
  .main {
    flex-direction: row;
    padding: var(--sk-space-48) 0;
  }

  .item {
    padding-right: 2rem;
  }

  .link {
    padding: 0;
  }

  .copyright {
    margin-top: inherit;
  }
}

/******************************
  UI Shift
*******************************/
/* stylelint-disable plugin/selector-bem-pattern */

[data-theme="jobteaser-uishift"] .link,
[data-theme="jobteaser-uishift"] .copyright {
  color: var(--sk-color-white);
}

}