@layer base {
  .main {
  display: flex;
  position: relative;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  flex: 1 0 auto;
  margin: 0.5rem;
  text-align: center;
}

.icon {
  width: auto;
  height: 20px;
}

}